<template>
	<edit :type="type"></edit>
</template>

<script>
	import edit from './template_edit.vue'
	
	export default {
		name: 'template_add',
		
		props: {
			type: String
		},
		
		components: {
			edit
		}
	}
</script>